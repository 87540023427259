// header on scroll
const header = document.querySelector(".header");

function onScroll() {
  if (window.scrollY >= 100) {
    header.classList.add("scrolled");
  } else {
    header.classList.remove("scrolled");
  }
}

window.addEventListener("scroll", onScroll);

// Form
document.addEventListener("DOMContentLoaded", function () {
  const inputElements = document.querySelectorAll(
    ".appraisal-form_input, select"
  );

  inputElements.forEach(input => {
    input.addEventListener("focus", function () {
      const formBlock = input.parentElement;
      formBlock.classList.add("focused");
    });

    input.addEventListener("blur", function () {
      const formBlock = input.parentElement;
      if (input.tagName === "SELECT") {
        if (input.selectedIndex === 0) {
          formBlock.classList.remove("focused");
        }
      } else {
        if (input.value.trim() === "") {
          formBlock.classList.remove("focused");
        }
      }
    });
  });
});

// Form pagination

document.addEventListener("DOMContentLoaded", function () {
  const appraisalForm = document.querySelector(".appraisal-form");

  const formPages = document.querySelectorAll(
    ".appraisal-form_page-holder .page"
  );
  const prevButton = document.querySelector(
    ".appraisal-form_pagination .appraisal-form_prev"
  );
  const nextButton = document.querySelector(
    ".appraisal-form_pagination .appraisal-form_next"
  );
  const formProgress = document.querySelector(
    ".appraisal-form_pagination .appraisal-form_progress"
  );

  if (appraisalForm !== null) {
    let currentPage = 0;

    let preisInput = document.getElementById("preis");
    preisInput.addEventListener("input", function (e) {
      let x = this.value.split(".").join("");
      x = x.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      this.value = x;
    });

    let datumInput = document.getElementById("datum");
    datumInput.addEventListener("keyup", function (e) {
      var val = this.value;
      val = val.replace(/[^\d.]/g, "").replace(/\./g, "");
      if (val.length >= 2) {
        val = val.substring(0, 2) + "." + val.substring(2);
      }
      if (val.length >= 5) {
        val = val.substring(0, 5) + "." + val.substring(5);
      }
      this.value = val.slice(0, 10);
    });

    datumInput.setAttribute("maxlength", "10");

    let nachrichtTextarea = document.getElementById("nachricht");
    nachrichtTextarea.addEventListener("input", function () {
      if (this.value.length > 300) {
        this.value = this.value.slice(0, 300);
      }
    });

    function validatePage(page) {
      const requiredFields = page.querySelectorAll(
        "input[required], select[required]"
      );
      let isValid = true;

      requiredFields.forEach(field => {
        const errorSpan = field.nextElementSibling.querySelector('.appraisal-form_error-label');
        if (field.value.trim() === "") {
          errorSpan.parentElement.classList.remove("hidden");
          isValid = false;
        } else {
          errorSpan.parentElement.classList.add("hidden");
        }

        // price validation
        if (field.id === "preis") {
          const priceRegex = /^\d{1,3}(\.\d{3})*$/;
          if (!priceRegex.test(field.value.trim())) {
            errorSpan.textContent = "Eingabe ungültig!";
            errorSpan.classList.remove("hidden");
            isValid = false;
          }
        }

        // Datum validation
        if (field.id === "datum") {
          const dateRegex =
            /^(0[1-9]|[12][0-9]|3[01])[.](0[1-9]|1[012])[.](20)\d\d$/;
          if (!dateRegex.test(field.value.trim())) {
            errorSpan.textContent = "Format ungültig: TT.MM.JJJJ";
            errorSpan.classList.remove("hidden");
            isValid = false;
          }
        }

        // Phone validation
        if (field.type === "tel") {
          const phoneRegex = /^[\s\-\+\(\)0-9]{7,20}$/;
          if (!phoneRegex.test(field.value.trim())) {
            errorSpan.textContent = "Telefonnummer ungültig!";
            errorSpan.classList.remove("hidden");
            isValid = false;
          }
        }

        // Email validation
        if (field.type === "email") {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (!emailRegex.test(field.value.trim())) {
            errorSpan.textContent = "E-Mail ungültig!";
            errorSpan.classList.remove("hidden");
            isValid = false;
          }
        }

        // hide error message when field is filled out
        field.addEventListener("change", function () {
          if (field.value.trim() !== "") {
            errorSpan.parentElement.classList.add("hidden");
          }
        });
      });

      return isValid;
    }

    function updatePagination() {
      formPages[currentPage].classList.remove("hidden");

      if (currentPage === 0) {
        prevButton.disabled = true;
      } else {
        prevButton.disabled = false;
      }

      if (currentPage === formPages.length - 1) {
        nextButton.disabled = true;
      } else {
        nextButton.disabled = false;
      }

      formProgress.textContent = `${currentPage + 1}/${formPages.length}`;
    }

    prevButton.addEventListener("click", function () {
      if (currentPage > 0) {
        formPages[currentPage].classList.add("hidden");
        currentPage--;
        updatePagination();
      }
    });

    nextButton.addEventListener("click", function () {
      if (currentPage < formPages.length - 1) {
        if (validatePage(formPages[currentPage])) {
          formPages[currentPage].classList.add("hidden");
          currentPage++;
          updatePagination();
        }
      }
    });

    updatePagination();
  }
});

// swiper
if (document.querySelector(".swiper-wrapper")) {
  document.addEventListener("DOMContentLoaded", function () {
    const carouselOptions = {
      slidesPerView: 1,
      spaceBetween: 16,
      freeMode: true,
      breakpoints: {
        1024: {
          slidesPerView: 3,
          spaceBetween: 24,
          freeMode: false,
          speed: 300,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 24,
          freeMode: false,
          speed: 300,
        },
      },
    };

    const carousel = new Swiper(".carousel", {
      ...carouselOptions,
      navigation: {
        nextEl: ".carousel-next",
        prevEl: ".carousel-prev",
      },
    });

    if (window.innerWidth < 1023) {
      const navbarCarousel = new Swiper(".navbar-carousel", {
        slidesPerView: 1,
        spaceBetween: 0,
        freeMode: true,
        navigation: {
          nextEl: ".nav-next",
          prevEl: ".nav-prev",
        },
      });
    }
  });
}

// faq toggle
document.addEventListener("DOMContentLoaded", function () {
  var faqHeaders = document.querySelectorAll(".faq_header");

  faqHeaders.forEach(faqHeader => {
    faqHeader.addEventListener("click", function () {
      var faq = faqHeader.closest(".faq");
      faq.classList.toggle("is-open");
    });
  });
});
